import styled, { css } from 'styled-components';

export const HamburgerWrapper = styled.button`
  height: 2rem;
  outline: none;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;

  > :last-child {
    margin-top: 1rem;
  }
`;

function getHamburgerTransition({ theme }) {
  return `transform ${theme.transitions.speeds.slow} ${theme.transitions.easings.default}`;
}

const HamburgerLine = css`
  width: 2.6rem;
  height: 0.2rem;
  background-color: ${({ color }) => color};
  transition: ${getHamburgerTransition};
`;

export const HamburgerLineTop = styled.div`
  ${HamburgerLine};
  ${({ isToggled }) =>
    isToggled && 'transform: translateY(0.58rem) rotate(-45deg)'};
`;

export const HamburgerLineBottom = styled.div`
  ${HamburgerLine};
  ${({ isToggled }) =>
    isToggled && 'transform: translateY(-0.58rem) rotate(45deg)'};
`;
