import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { useAnimationQueue } from './AnimationQueue.helpers';

const AnimationItem = React.forwardRef(
  ({ Component, children, ...props }, ref) => {
    const innerRef = useRef();

    useImperativeHandle(ref, () => innerRef.current);

    const { comingIn, duration, classNames } = useAnimationQueue();

    return (
      <CSSTransition
        nodeRef={innerRef}
        in={comingIn}
        timeout={duration}
        classNames={classNames}
      >
        <Component {...props} ref={innerRef}>
          {children}
        </Component>
      </CSSTransition>
    );
  },
);

AnimationItem.propTypes = {
  Component: PropTypes.elementType.isRequired,
  children: PropTypes.node,
};

AnimationItem.defaultProps = {
  children: null,
};

export default AnimationItem;
