import { arrayOf, string } from 'prop-types';
import { shape, and } from 'airbnb-prop-types';

const categoryBaseShape = shape({
  categoryId: string,
  slug: string,
  title: string,
});

export const subcategoryShape = and([
  categoryBaseShape,
  shape({
    image: string,
  }),
]);

export const categoryShape = and([
  categoryBaseShape,
  shape({
    children: arrayOf(subcategoryShape),
  }),
]);
