import styled, { createGlobalStyle, css } from 'styled-components';

import {
  centeredGridColumns,
  getGridBase,
  getThemeColor,
  getThemePath,
  getThemeTransition,
  getTypographyStyle,
  media,
} from '@utils/styled';

import { BODY_RESIZING_CLASS } from '@common/constants/body';
import { NAVBAR_HEIGHT } from '@common/constants/ui';
import { ArrowForwardNavigation } from '@common/components/Icons';

export const GlobalStyle = createGlobalStyle`
  body {
    ${({ isOpen }) => isOpen && 'overflow: hidden;'}
  }

  .menu-item-enter,
  .menu-item-exit-done {
    opacity: 0;
    transform: translateY(10rem);
    pointer-events: none;
  }

  .menu-item-enter-done {
    opacity: 1;
    transition: ${getThemeTransition('opacity', 'mediumSlow')},
      ${getThemeTransition('transform', 'mediumSlow')};
    transition-delay: 150ms;
    transform: translateY(0);
  }

  .menu-item-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .menu-item-exit-done {
    opacity: 0;
    transition: ${getThemeTransition('opacity', 'mediumSlow')},
      ${getThemeTransition('transform', 'mediumSlow')};
    transform: translateY(10rem);
    pointer-events: none;
  }
`;

export const MenuStyled = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
  width: 100vw;
  height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  ${({ isOpen, isExiting }) =>
    (isOpen || isExiting) && `padding-top: ${NAVBAR_HEIGHT}rem;`}
  background-color: ${getThemeColor('grey.100')};

  will-change: height;
  transition: ${getThemeTransition('height', 'slow')};
  ${({ isExiting }) =>
    isExiting &&
    css`
      transition-delay: ${getThemePath(['transitions', 'speeds', 'medium'])};
    `}

  body.${BODY_RESIZING_CLASS} & {
    transition: none;
  }
`;

export const MenuContent = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

const MenuLevel = styled.div`
  width: 100%;
  height: 100%;

  body.${/* sc-sel */ BODY_RESIZING_CLASS} & {
    transition: none;
  }
`;

export const MenuFirstLevel = styled(MenuLevel)`
  transition: ${getThemeTransition('transform', 'verySlow', 'default')};
  transform: translate3d(
    ${({ isSecondLevelOpen }) => (isSecondLevelOpen ? '-100vw' : '0vw')},
    0,
    0
  );

  ${({ disableSlideInAnimation }) =>
    disableSlideInAnimation &&
    css`
      transition: none;
    `};
`;

export const MenuSecondLevel = styled(MenuLevel)`
  position: absolute;
  top: 0;
  width: 100vw;
  transition: ${({ isOpen }) =>
    isOpen
      ? css`
          ${getThemeTransition('transform', 'slow', 'default')}
        `
      : css`
          ${getThemeTransition('transform', 'slow', 'default')}
        `};
  transform: translate3d(${({ isOpen }) => (isOpen ? '0' : '100%')}, 0, 0);

  ${({ disableSlideInAnimation }) =>
    disableSlideInAnimation &&
    css`
      transition: none;
    `};
`;

export const MenuLevelScroll = styled.div`
  overflow-y: auto;
  margin-inline: auto;
  max-width: 176rem;
  height: 100%;
  ${({ theme }) =>
    theme.getFluidSpacing(
      ['padding-left', 'padding-right'],
      'widthBased',
      0,
      false,
      'safe-area-inset-right',
    )};
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MenuLevelSection = styled.div`
  ${getGridBase()};
  margin: 0 auto;
`;

export const MenuLevelGridSectionContent = styled.div`
  position: relative;
  grid-column: ${centeredGridColumns(12)};
  max-width: 100%;
  height: 100%;

  ${media.from640up`
    grid-column: ${centeredGridColumns(10)};
  `}

  ${media.from1200up`
    grid-column: ${centeredGridColumns(8)};
  `}
`;

export const MenuLevelContent = styled.div`
  flex: 1 0 auto;
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'section', 3)};
`;

export const ProductRelatedLinks = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  ${({ theme }) => theme.getFluidSpacing('gap', 'widthBased', 2)}
  margin: 0;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'widthBased', 2)}
  padding: 0;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'widthBased', 2)};
  list-style: none;
`;

export const ProductRelatedItem = styled.li`
  ${({ isFullWidth }) =>
    isFullWidth
      ? css`
          grid-column: span 6;
        `
      : css`
          grid-column: span 3;

          ${media.from800up`
        grid-column: span 2;
      `}
        `}
`;

export const ChevronIcon = styled(ArrowForwardNavigation).attrs({
  width: 11,
  height: 20,
})`
  ${({ theme }) => theme.getFluidAttribute('width', 0.605, 1.1)}
  ${({ theme }) => theme.getFluidAttribute('height', 1.1, 2)}
  margin-left: 0.2em;
  ${({ $left }) => $left && 'transform: rotate(180deg);'}
`;

export const TwoColumns = styled.div`
  ${media.from800up`
    column-count: 2;
    column-fill: balance;
    ${({ theme }) => theme.getFluidSpacing('column-gap', 'scale', 1)}
  `}
`;

export const MenuLink = styled.a`
  display: flex;
  align-items: center;
  ${({ theme }) =>
    theme.getFluidSpacing(['padding-top', 'padding-bottom'], 'scale', 5)}
  color: ${getThemeColor('bluegrey.15')};
  font-weight: 500;
  font-size: 1.6rem;
  text-decoration: none;

  &:not(.menu-item-enter-done, .menu-item-exit-done) {
    transition: ${getThemeTransition('background-color')};
  }

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }

  &:focus-visible {
    background-color: ${getThemeColor('bluegrey.95')};
  }

  ${media.from800up`
    ${getTypographyStyle('h5')};
  `}
`;
