import { string, number, bool } from 'prop-types';
import { shape, or } from 'airbnb-prop-types';

export const UrlShape = shape({
  auth: string,
  hash: string,
  host: string,
  hostname: string,
  href: string,
  pathname: string,
  protocol: string,
  search: string,
  slashes: or([bool, string]),
  port: or([string, number, string]),
  query: or([string, shape({})]),
});

export const UrlType = or([string, UrlShape]);
