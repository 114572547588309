import styled from 'styled-components';

import { getThemeColor, getThemePath, getTypographyStyle } from '@utils/styled';

import LazyImg from '@common/components/LazyImg';

export const ImageLinkStyled = styled.a`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  width: 100%;
  ${({ isFullWidth, theme }) =>
    theme.getFluidAttribute(
      'height',
      ...(isFullWidth ? [12.5, 35] : [8.3, 18.1]),
    )}
  border: none;
  border-radius: ${getThemePath(['borderRadiuses', 'normal.2'])}rem;
  ${({ theme }) => theme.getFluidSpacing('padding', 'widthBased', 5)};
  ${getTypographyStyle('mainMenu')}
  color: ${getThemeColor('grey.100')};
  cursor: pointer;
  clip-path: border-box;

  &:hover {
    text-decoration: none;
  }

  svg {
    fill: ${getThemeColor('grey.100')};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.06) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
`;

export const ImageLinkText = styled.div`
  z-index: 1;

  display: flex;
  align-items: center;
`;

export const LazyImage = styled(LazyImg)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
