export const SHOP_EVENT_STATUS = {
  OPEN: 'OPEN',
  UPCOMING: 'UPCOMING',
  INACTIVE: 'INACTIVE',
};

export const SHOP_EVENT_AVAILABILITY_LABEL = {
  LOW: 'LOW',
  HIGH: 'HIGH',
  SOLDOUT: 'SOLDOUT',
};
