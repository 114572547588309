import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

import { selectShopEventStatus } from '@selectors/shopEvent';
import { selectMainMenuItems } from '@selectors/menus';

import { Content, ContentContainer } from '@routes/CMSPage/CMSPage.styled';

import { getRouteUrl } from '@utils/urls';

import {
  MenuFirstLevel,
  MenuLevelContent,
  MenuLevelGridSectionContent,
  MenuLevelScroll,
  MenuLevelSection,
  ProductRelatedItem,
  ProductRelatedLinks,
} from '../Menu.styled';
import AnimationQueue, {
  AnimationItem,
} from '@common/components/AnimationQueue';
import ImageLink from '../ImageLink';
import {
  PRODUCTS_ALL,
  PRODUCTS_NEW,
  PRODUCTS_OFFER,
  PRODUCTS_SALE,
  SHOP_EVENT,
} from '@common/routes';
import { MENU } from '@common/images';
import CmsLinks from '../CmsLinks';
import { SHOP_EVENT_STATUS } from '@common/constants/shopEvent';
import LanguageSwitcher from '../LanguageSwitcher';
import { CategoryTiles } from '../CategoryTiles';
import { MENU_ITEM_ANIMATION_DURATION } from '../Menu.constants';

export const CategoryMenuFirstLevel = ({
  categories,
  animationQueueIsOpen,
  menuSecondLevelIsOpen,
  disableSlideInAnimation,
  openedSubmenu,
  setOpenedSubmenu,
}) => {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  const { shopEventStatus, menuImage: shopEventMenuImage } = useSelector(
    selectShopEventStatus,
  );

  const mainMenuCmsItems = useSelector(selectMainMenuItems);

  const shopEventLinkAs = getRouteUrl(currentLanguage, SHOP_EVENT);
  const shopEventMenuItem = useMemo(
    () =>
      shopEventStatus === SHOP_EVENT_STATUS.OPEN &&
      mainMenuCmsItems.find(({ linkUrl }) => linkUrl.endsWith(shopEventLinkAs)),
    [mainMenuCmsItems, shopEventStatus], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <MenuFirstLevel
      isSecondLevelOpen={menuSecondLevelIsOpen}
      disableSlideInAnimation={disableSlideInAnimation}
    >
      <MenuLevelScroll>
        <MenuLevelContent>
          <MenuLevelSection>
            <MenuLevelGridSectionContent>
              <ContentContainer>
                <Content>
                  <AnimationQueue
                    in={animationQueueIsOpen}
                    duration={MENU_ITEM_ANIMATION_DURATION}
                    classNames="menu-item"
                  >
                    <ProductRelatedLinks>
                      {shopEventMenuItem && (
                        <AnimationItem
                          Component={ProductRelatedItem}
                          isFullWidth
                        >
                          <ImageLink
                            linkAs={shopEventLinkAs}
                            linkHref={SHOP_EVENT}
                            image={shopEventMenuImage}
                            isFullWidth
                          >
                            {shopEventMenuItem.title}
                          </ImageLink>
                        </AnimationItem>
                      )}

                      <AnimationItem Component={ProductRelatedItem}>
                        <ImageLink
                          linkAs={getRouteUrl(currentLanguage, PRODUCTS_NEW)}
                          linkHref={PRODUCTS_NEW}
                          image={MENU.NEW}
                        >
                          {t('navbar.newProductsLabel', 'Neuhei')}
                        </ImageLink>
                      </AnimationItem>

                      <AnimationItem Component={ProductRelatedItem}>
                        <ImageLink
                          linkAs={getRouteUrl(currentLanguage, PRODUCTS_ALL)}
                          linkHref={PRODUCTS_ALL}
                          image={MENU.ALL}
                        >
                          {t('categoryMain.allProducts', 'Alle Produkte')}
                        </ImageLink>
                      </AnimationItem>

                      <CategoryTiles
                        categories={categories}
                        setOpenedSubmenu={setOpenedSubmenu}
                        openedSubmenu={openedSubmenu}
                        disableSlideInAnimation={disableSlideInAnimation}
                      />

                      <AnimationItem Component={ProductRelatedItem}>
                        <ImageLink
                          linkHref={PRODUCTS_SALE}
                          linkAs={getRouteUrl(currentLanguage, PRODUCTS_SALE)}
                          image={MENU.SALE}
                        >
                          {t('sale', 'Sale')}
                        </ImageLink>
                      </AnimationItem>

                      <AnimationItem Component={ProductRelatedItem}>
                        <ImageLink
                          linkHref={PRODUCTS_OFFER}
                          linkAs={getRouteUrl(currentLanguage, PRODUCTS_OFFER)}
                          image={MENU.OFFER}
                        >
                          {t('offer', 'Angebot')}
                        </ImageLink>
                      </AnimationItem>
                    </ProductRelatedLinks>

                    <CmsLinks
                      menuItems={mainMenuCmsItems}
                      shopEventMenuItemId={shopEventMenuItem?.id}
                    />

                    <AnimationItem Component={LanguageSwitcher} />
                  </AnimationQueue>
                </Content>
              </ContentContainer>
            </MenuLevelGridSectionContent>
          </MenuLevelSection>
        </MenuLevelContent>
      </MenuLevelScroll>
    </MenuFirstLevel>
  );
};

CategoryMenuFirstLevel.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({ categoryId: PropTypes.string }),
  ).isRequired,
  animationQueueIsOpen: PropTypes.bool.isRequired,
  menuSecondLevelIsOpen: PropTypes.bool.isRequired,
  disableSlideInAnimation: PropTypes.bool,
  openedSubmenu: PropTypes.string,
  setOpenedSubmenu: PropTypes.func.isRequired,
};

CategoryMenuFirstLevel.defaultProps = {
  disableSlideInAnimation: false,
  openedSubmenu: undefined,
};
