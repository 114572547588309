import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { getRouteUrl } from '@utils/urls';

import { AnimationItem } from '@common/components/AnimationQueue';
import { PRODUCTS_CATEGORY } from '@common/routes';
import ImageLink from '../ImageLink';
import { MENU } from '@common/images';
import { ChevronIcon } from '../Menu.styled';

export const CategoryTileItem = ({ category, setOpenedSubmenu }) => {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  if (category.children.length === 1) {
    const [{ title, categoryId, slug } = {}] = category.children;

    return (
      <AnimationItem
        Component={ImageLink}
        linkHref={{
          pathname: PRODUCTS_CATEGORY,
          query: {
            id: categoryId,
            slug,
          },
        }}
        linkAs={getRouteUrl(currentLanguage, PRODUCTS_CATEGORY, {
          id: categoryId,
          slug,
        })}
        image={MENU.CATEGORIES[category.categoryId]}
      >
        {title}
      </AnimationItem>
    );
  }

  return (
    <AnimationItem
      Component={ImageLink}
      image={MENU.CATEGORIES[category.categoryId]}
      onClick={() => setOpenedSubmenu(category.slug)}
      isButton
    >
      {category.title}
      <ChevronIcon />
    </AnimationItem>
  );
};

CategoryTileItem.propTypes = {
  category: PropTypes.shape({
    categoryId: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.string,
        slug: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }).isRequired,
  setOpenedSubmenu: PropTypes.func.isRequired,
};
