import { useEffect, useState } from 'react';

import * as endpoints from '@common/endpoints';

export const deviceCheck = () =>
  fetch(endpoints.getDeviceInfo(), {
    method: 'GET',
    cache: 'no-cache',
  });

const checkDeviceTypeForMobile = device =>
  device?.type === 'tablet' || device?.type === 'smartphone';

export const isAndroid = async () => {
  const data = await deviceCheck();

  return data.os.name === 'Android';
};

export const isIOS = async () => {
  const data = await deviceCheck();

  return data.os.name === 'iOS';
};

export const isMobile = async () => {
  const data = await deviceCheck();

  return checkDeviceTypeForMobile(data.device);
};

export const useDevice = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    iOS: false,
    android: false,
    isMobile: true,
  });

  useEffect(() => {
    deviceCheck().then(async data => {
      const response = await data.json();

      return setDeviceInfo({
        iOS: response.os.name === 'iOS',
        android: response.os.name === 'Android',
        isMobile: checkDeviceTypeForMobile(response.device),
      });
    });
  }, []);

  return {
    ...deviceInfo,
  };
};

export default {
  useDevice,
};
