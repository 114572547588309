import styled, { css } from 'styled-components';

import { getThemeColor, getThemeTransition } from '@utils/styled';

export const IconLinkWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  transition: ${getThemeTransition('filter')};

  ${({ hasIndicator }) =>
    hasIndicator &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: -0.25rem;
        right: -0.5rem;
        width: 10px;
        height: 10px;
        border: 2px solid ${getThemeColor('grey.0')};
        border-radius: 50%;
        background-color: ${getThemeColor('hue.red')};
      }
    `}

  &:hover {
    filter: brightness(75%);
  }

  svg {
    display: block;
  }
`;
