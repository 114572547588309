import React from 'react';
import { useRouter } from 'next/router';

import { getRouteUrl, getLinkDataFromUrl } from '@utils/urls';

import { useLanguageUrls } from '@common/contexts/languageUrlsContext';
import { LANGUAGES } from '@common/constants/languages';

import { LanguageItem, Languages } from './LanguageSwitcher.styled';

const LanguageSwitcher = React.forwardRef((props, ref) => {
  const { pathname, query, locale } = useRouter();
  const langUrls = useLanguageUrls();

  return (
    <Languages ref={ref}>
      {LANGUAGES.map(language => {
        const href = langUrls
          ? getLinkDataFromUrl(langUrls[language]).as
          : getRouteUrl(language, pathname, query);

        return (
          <LanguageItem
            key={language}
            href={href}
            isActive={language === locale}
          >
            {language.toUpperCase()}
          </LanguageItem>
        );
      })}
    </Languages>
  );
});

export default LanguageSwitcher;
