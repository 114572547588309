import React from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { selectCompanyMenu, selectForClientsMenu } from '@selectors/menus';

import { PREFIXED_INTERNAL_LINK_DOMAINS } from '@utils/domains';
import { getLinkDataFromUrl } from '@utils/urls';

import {
  LinksWrapper,
  LinksSection,
  SectionTitle,
  MenuLink,
} from './Links.styled';

const Links = () => {
  const { t } = useTranslation();
  const forClientsMenu = useSelector(selectForClientsMenu);
  const companyMenu = useSelector(selectCompanyMenu);

  return (
    <LinksWrapper>
      <LinksSection>
        <SectionTitle>{t('forClients', 'Für Kundinnen')}</SectionTitle>
        {forClientsMenu?.menuItems.map(item => {
          const url =
            PREFIXED_INTERNAL_LINK_DOMAINS.some(domain =>
              item.linkUrl?.includes(domain),
            ) && item.linkUrl;
          const linkData = url && getLinkDataFromUrl(url);

          if (linkData) {
            return (
              <Link
                legacyBehavior
                href={linkData.urlObject}
                as={linkData.as}
                passHref
                key={item.id}
              >
                <MenuLink>{item.title}</MenuLink>
              </Link>
            );
          }

          return (
            <MenuLink
              href={item.linkUrl}
              key={item.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.title}
            </MenuLink>
          );
        })}
      </LinksSection>
      <LinksSection>
        <SectionTitle>{t('company', 'Company')}</SectionTitle>
        {companyMenu?.menuItems.map(item => {
          const url =
            PREFIXED_INTERNAL_LINK_DOMAINS.some(domain =>
              item.linkUrl?.includes(domain),
            ) && item.linkUrl;
          const linkData = url && getLinkDataFromUrl(url);

          if (linkData) {
            return (
              <Link
                legacyBehavior
                href={linkData.urlObject}
                as={linkData.as}
                passHref
                key={item.id}
              >
                <MenuLink>{item.title}</MenuLink>
              </Link>
            );
          }

          return (
            <MenuLink
              href={item.linkUrl}
              key={item.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.title}
            </MenuLink>
          );
        })}
      </LinksSection>
    </LinksWrapper>
  );
};

export default Links;
