import styled from 'styled-components';

export const LogoLink = styled.a`
  position: relative;
  left: -1.2rem;
  display: flex;
`;

export const LogoImg = styled.img`
  width: auto;
  height: 5rem;
`;
