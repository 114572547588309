import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeFeatureDiscovery } from '@actions/favorites';
import { selectIsFeatureDiscoveryVisible } from '@selectors/favorites';

import { MainMenuProvider } from './Navbar.helpers';
import NavFocusTrapWrapper from './NavFocusTrapWrapper';
import NavPoppers from './NavPoppers';
import Header from './Header';
import Menu from './Menu';

export function Navbar() {
  const dispatch = useDispatch();

  const isFavoritesPopperVisible = useSelector(selectIsFeatureDiscoveryVisible);
  const handleClosePopper = useCallback(() => {
    if (isFavoritesPopperVisible) {
      dispatch(closeFeatureDiscovery());
    }
  }, [dispatch, isFavoritesPopperVisible]);

  return (
    <MainMenuProvider onClose={handleClosePopper}>
      <NavFocusTrapWrapper
        isFocusTrapActive={isFavoritesPopperVisible}
        onFocusTrapDeactivate={handleClosePopper}
      >
        <NavPoppers />

        <Header />

        <Menu />
      </NavFocusTrapWrapper>
    </MainMenuProvider>
  );
}

export default Navbar;
