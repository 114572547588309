import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

import { selectCategoryIcons } from '@selectors/categories';

import { getRouteUrl } from '@utils/urls';

import { subcategoryShape } from '@common/types/category';
import { CATEGORY_COMMON_ICON } from '@common/images';
import { PRODUCTS_CATEGORY } from '@common/routes';
import { MenuLink } from '../../Menu.styled';

import { CategoryIcon } from './CategoryColumnItem.styled';

const CategoryColumnItem = ({ item: { categoryId: id, slug, title } }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const categoryIcons = useSelector(selectCategoryIcons);

  return (
    <Link
      legacyBehavior
      href={{
        pathname: PRODUCTS_CATEGORY,
        query: {
          id,
          slug,
        },
      }}
      as={getRouteUrl(language, PRODUCTS_CATEGORY, {
        id,
        slug,
      })}
      passHref
    >
      <MenuLink>
        <CategoryIcon src={categoryIcons[id] || CATEGORY_COMMON_ICON} />
        {title}
      </MenuLink>
    </Link>
  );
};

CategoryColumnItem.propTypes = {
  item: subcategoryShape.isRequired,
};

export default CategoryColumnItem;
