import React from 'react';
import PropTypes from 'prop-types';

import { Content, ContentContainer } from '@routes/CMSPage/CMSPage.styled';

import { MENU_ITEM_ANIMATION_DURATION } from '../Menu.constants';
import {
  MenuLevelContent,
  MenuLevelGridSectionContent,
  MenuLevelScroll,
  MenuLevelSection,
  MenuSecondLevel,
} from '../Menu.styled';
import AnimationQueue from '@common/components/AnimationQueue';
import CategorySubmenu from '../CategorySubmenu';
import { usePrevious } from '@common/hooks/usePrevious';

export const CategoryMenuSecondLevel = ({
  menuSecondLevelIsOpen,
  animationQueueIsOpen,
  setOpenedSubmenu,
  setActiveCategory,
  category,
}) => {
  const previousCategory = usePrevious(category);

  return (
    <MenuSecondLevel isOpen={menuSecondLevelIsOpen}>
      <MenuLevelScroll>
        <MenuLevelContent>
          <MenuLevelSection>
            <MenuLevelGridSectionContent>
              <ContentContainer>
                <Content>
                  <AnimationQueue
                    in={animationQueueIsOpen}
                    duration={MENU_ITEM_ANIMATION_DURATION}
                    classNames="menu-item"
                  >
                    {(category || previousCategory) && (
                      <CategorySubmenu
                        category={category || previousCategory}
                        closeSubmenu={() => {
                          setOpenedSubmenu(null);
                          setActiveCategory(null);
                        }}
                      />
                    )}
                  </AnimationQueue>
                </Content>
              </ContentContainer>
            </MenuLevelGridSectionContent>
          </MenuLevelSection>
        </MenuLevelContent>
      </MenuLevelScroll>
    </MenuSecondLevel>
  );
};

CategoryMenuSecondLevel.propTypes = {
  menuSecondLevelIsOpen: PropTypes.bool.isRequired,
  animationQueueIsOpen: PropTypes.bool.isRequired,
  setOpenedSubmenu: PropTypes.func.isRequired,
  setActiveCategory: PropTypes.func.isRequired,
  category: PropTypes.shape({
    categoryId: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        categoryId: PropTypes.string,
        slug: PropTypes.string,
      }),
    ),
  }),
};

CategoryMenuSecondLevel.defaultProps = {
  category: undefined,
};
