import styled, { keyframes } from 'styled-components';

import { getThemeColor, getThemeTransition } from '@utils/styled';

import { IS_SCROLLING_DOWN_CLASS } from '@common/constants/body';
import { NAVBAR_HEIGHT } from '@common/constants/ui';

export const HeaderStyled = styled.header`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  min-height: ${NAVBAR_HEIGHT}rem;
  max-height: ${NAVBAR_HEIGHT}rem;
  background-color: black;
  transition: ${getThemeTransition('transform', 'fast')};
  ${({ isVisible }) =>
    isVisible && 'transform: translate3d(0, 0%, 0) !important;'}

  .${IS_SCROLLING_DOWN_CLASS} & {
    transform: translate3d(0, -100%, 0);
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints[0]}rem;
  height: 100%;
  ${({ theme }) =>
    theme.getFluidSpacing(
      'padding-left',
      'widthBased',
      0,
      false,
      'safe-area-inset-left',
    )};
  ${({ theme }) =>
    theme.getFluidSpacing(
      'padding-right',
      'widthBased',
      0,
      false,
      'safe-area-inset-right',
    )};
  margin: 0 auto;
  color: ${getThemeColor('grey.100')};
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const LoadingIndicatorWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.2;
  width: 100%;
  height: 4px;
  background-color: rgba(153, 153, 153, 0.8);
`;

const loadingAnimation = keyframes`
  0% {
    right: 100%;
    left: -100%;
  }

  100% {
    right: -100%;
    left: 100%;
  }
`;

export const LoadingIndicator = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 60rem;
  min-width: 5rem;
  max-width: 60vw;
  height: 4px;
  background-color: rgba(102, 102, 102, 0.8);
  transition: transform 0.2s linear;
  transform-origin: left;
  animation: ${loadingAnimation} 1.5s infinite cubic-bezier(0.64, 0, 0.64, 1);
`;
