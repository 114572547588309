import React from 'react';
import Link from 'next/link';

import { PREFIXED_INTERNAL_LINK_DOMAINS } from '@utils/domains';
import { getLinkDataFromUrl } from '@utils/urls';

import { menuItemShape } from '@common/types/cms';
import { MenuLink } from '../Menu.styled';

const CmsLink = ({ item: { linkUrl, title } }) => {
  const linkData = PREFIXED_INTERNAL_LINK_DOMAINS.some(domain =>
    linkUrl?.includes(domain),
  )
    ? getLinkDataFromUrl(linkUrl)
    : null;

  return linkData ? (
    <Link
      legacyBehavior
      href={linkData.urlObject}
      as={linkData.as}
      locale={false}
      passHref
    >
      <MenuLink>{title}</MenuLink>
    </Link>
  ) : (
    <MenuLink href={linkUrl}>{title}</MenuLink>
  );
};

CmsLink.propTypes = {
  item: menuItemShape.isRequired,
};

export default CmsLink;
