import React from 'react';
import PropTypes from 'prop-types';

import {
  HamburgerLineBottom,
  HamburgerLineTop,
  HamburgerWrapper,
} from './Hamburger.styled';

export function Hamburger({ color, isToggled, onClick }) {
  return (
    <HamburgerWrapper onClick={onClick} type="button">
      <HamburgerLineTop isToggled={isToggled} color={color} />
      <HamburgerLineBottom isToggled={isToggled} color={color} />
    </HamburgerWrapper>
  );
}

Hamburger.propTypes = {
  color: PropTypes.string,
  isToggled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

Hamburger.defaultProps = {
  color: 'white',
};

export default Hamburger;
