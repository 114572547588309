import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { selectShopEventStatus } from '@selectors/shopEvent';
import {
  selectBasketHasProducts,
  selectIsAddToBasketToolTipVisible,
} from '@selectors/basket';

import { getRouteUrl } from '@utils/urls';

import { BASKET } from '@common/routes';
import { SHOP_EVENT_STATUS } from '@common/constants/shopEvent';
import { ShoppingBag as ShoppingBagIcon } from '@common/components/Icons';
import { useMainMenu } from '../../Navbar.helpers';

import { IconLinkWrapper } from './common.styled';

const BasketLink = () => {
  const { locale } = useRouter();
  const { popper } = useMainMenu();

  const { shopEventStatus } = useSelector(selectShopEventStatus);
  const basketHasProducts = useSelector(selectBasketHasProducts);
  const isAddToBasketPopperVisible = useSelector(
    selectIsAddToBasketToolTipVisible,
  );

  if (shopEventStatus !== SHOP_EVENT_STATUS.OPEN) {
    return null;
  }

  return (
    <IconLinkWrapper
      data-testid="basketIcon"
      ref={element => {
        if (isAddToBasketPopperVisible) {
          popper.setReferenceElement(element);
        }
      }}
      hasIndicator={basketHasProducts}
    >
      <Link legacyBehavior href={BASKET} as={getRouteUrl(locale, BASKET)}>
        <a>
          <ShoppingBagIcon fill="white" />
        </a>
      </Link>
    </IconLinkWrapper>
  );
};

export default BasketLink;
