import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';
import { ChevronIcon } from '../Menu.styled';

export const BackButton = styled.button.attrs({ type: 'button' })`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.1rem;
  outline: none;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${getThemeColor('bluegrey.80')};
  padding: 0;
  background: ${getThemeColor('grey.100')};
  color: ${getThemeColor('bluegrey.15')};
  cursor: pointer;

  svg {
    margin-right: 0.9rem;
    font-size: 0;
    fill: ${getThemeColor('bluegrey.70')};
  }
`;

export const MenuTitle = styled(Typography).attrs({
  customAs: 'span',
  variant: 'paragraph2',
})`
  position: relative;
  top: 1px;
  display: block;
  margin: 0;
  font-weight: 500;
`;

export const BackChevronIcon = styled(ChevronIcon)`
  width: 1.1rem;
  height: 2rem;
`;

export const ExploreAllChevronIcon = styled(ChevronIcon)`
  ${({ theme }) => theme.getFluidAttribute('width', 0.7, 1.1)}
  ${({ theme }) => theme.getFluidAttribute('height', 1.2, 2)}
`;
