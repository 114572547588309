import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const LinksWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  ${({ theme }) => theme.getFluidSpacing('grid-row-gap', 'equal', 1)};
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 2)}
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 2)}
  border-bottom: 1px solid ${getThemeColor('bluegrey.90')};

  ${media.from480up`
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: none;
    ${({ theme }) => theme.getFluidSpacing('grid-column-gap', 'widthBased', 1)};
  `}
`;

export const LinksSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${({ theme }) => theme.getFluidSpacing('grid-column-gap', 'widthBased', 1)};
  ${({ theme }) => theme.getFluidSpacing('grid-row-gap', 'equal', 2)}
  justify-items: center;

  ${media.from1040up`
    justify-items: start;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  `}
`;

export const SectionTitle = styled(Typography).attrs({
  variant: 'paragraph1',
  customAs: 'h6',
})`
  width: 100%;
  margin: 0;
  color: ${getThemeColor('bluegrey.70')};
  font-weight: 700;
  text-align: center;

  ${media.from1040up`
    text-align: start;
    grid-column: 1 / span 2;
  `}
`;

export const MenuLink = styled(Typography).attrs({
  customAs: 'a',
  variant: 'body2',
})`
  margin: 0;
  color: ${getThemeColor('bluegrey.15')};

  &:hover {
    text-decoration: none;
  }

  &&&::after {
    display: none;
  }
`;
