import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

export const Wrapper = styled.div`
  ${media.from640up`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${({ theme }) => theme.getFluidSpacing('column-gap', 'scale', 20)}
  `}

  ${media.from800up`
    ${({ theme }) => theme.getFluidSpacing('column-gap', 'scale', 2)}
  `};
`;

export const Column = styled.div``;

export const Item = styled.div`
  border: solid ${getThemeColor('bluegrey.90')};
  border-width: 1px 0 0;

  ${media.from800up`
    &:last-child {
      border-width: 1px 0;
    }

    &:first-child {
      border-bottom: 0;
    }
  `}

  ${media.from720down`
    ${Column}:last-child > &:last-child {
      border-width: 1px 0;
    }
  `}
`;
