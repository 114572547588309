import React from 'react';
import PropTypes from 'prop-types';

import Socials from './Socials';
import Links from './Links';
import Credits from './Credits';
import { FooterWrapper } from './Footer.styled';

export const Footer = ({ noSpacingTop }) => (
  <FooterWrapper hasSpacingTop={!noSpacingTop}>
    <Socials />
    <Links />
    <Credits />
  </FooterWrapper>
);

Footer.propTypes = {
  noSpacingTop: PropTypes.bool,
};

Footer.defaultProps = {
  noSpacingTop: false,
};

export default Footer;
