import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const CreditsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('gap', 'equal', 3)}
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 3)}
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 3)};

  ${media.from1040up`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const MiddleCredits = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('gap', 'equal', 3)};

  ${media.from1040up`
    flex-direction: row;
  `}
`;

export const CreditText = styled(Typography).attrs(({ customAs }) => ({
  customAs: customAs || 'span',
  variant: 'body2',
}))`
  margin: 0;
  color: ${getThemeColor('bluegrey.70')};

  &&&::after {
    display: none;
  }
`;
