import styled from 'styled-components';

import { media, getThemeColor } from '@utils/styled';

import { ButtonLink } from '@common/components/Button';

export const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('gap', 'equal', 3)}
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 2)}
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 2)}
  border-top: 1px solid ${getThemeColor('bluegrey.90')};
  border-bottom: 1px solid ${getThemeColor('bluegrey.90')};

  ${media.from880up`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const SocialIconsWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'equal', 3)}
`;

export const StoreLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('gap', 'scale', 5)};

  img {
    max-height: 50px;
  }

  ${media.from480up`
    flex-direction: row;
  `}
`;

export const SocialIconsLink = styled.a`
  display: inline-flex;

  &&&::after {
    display: none;
  }

  svg {
    pointer-events: none;
  }
`;

export const StoreLink = styled(SocialIconsLink)`
  height: 100%;
`;

export const NewsletterButton = styled(ButtonLink)`
  width: auto;
  font-weight: 500;
`;
