import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

export const CategoryIcon = styled.img`
  ${({ theme }) => theme.getFluidAttribute('width', 3, 5)}
  ${({ theme }) => theme.getFluidAttribute('height', 3, 5)}
  ${({ theme }) => theme.getFluidAttribute('margin-right', 0.9, 1.3)}
  fill: ${getThemeColor('bluegrey.70')};
`;
