import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { explicitNull, or } from 'airbnb-prop-types';

import { menuItemsType } from '@common/types/cms';
import Columns from '../Columns';

import CmsLink from './CmsLink';

const CmsLinks = ({ menuItems, shopEventMenuItemId }) => {
  const filteredItems = useMemo(
    () =>
      !shopEventMenuItemId
        ? menuItems
        : menuItems.filter(({ id }) => id !== shopEventMenuItemId),
    [menuItems, shopEventMenuItemId],
  );

  return <Columns items={filteredItems} ItemComponent={CmsLink} />;
};

CmsLinks.propTypes = {
  menuItems: menuItemsType.isRequired,
  shopEventMenuItemId: or([explicitNull(), PropTypes.number]),
};

CmsLinks.defaultProps = {
  shopEventMenuItemId: undefined,
};

export default CmsLinks;
