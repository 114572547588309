import React from 'react';
import PropTypes from 'prop-types';

import { ProductRelatedItem } from '../Menu.styled';

import { CategoryTileItem } from './CategoryTileItem';

export const CategoryTiles = ({ categories, setOpenedSubmenu }) =>
  categories.map(category => (
    <ProductRelatedItem key={category.categoryId}>
      <CategoryTileItem
        category={category}
        setOpenedSubmenu={setOpenedSubmenu}
      />
    </ProductRelatedItem>
  ));

CategoryTiles.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          categoryId: PropTypes.string,
          slug: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  setOpenedSubmenu: PropTypes.func.isRequired,
};
