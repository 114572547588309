import { createContext, useContext, useEffect, useId, useMemo } from 'react';

export const AnimationQueueContext = createContext({
  comingIn: false,
  classNames: '',
  getDuration() {},
  registerItem() {},
  unregisterItem() {},
});

AnimationQueueContext.displayName = 'AnimationQueueContext';

export const useAnimationQueue = () => {
  const id = useId();
  const { getDuration, registerItem, unregisterItem, ...animationQueue } =
    useContext(AnimationQueueContext);

  useEffect(() => {
    registerItem(id);

    return () => {
      unregisterItem(id);
    };
  }, [id, registerItem, unregisterItem]);

  const duration = useMemo(() => getDuration(id), [getDuration, id]);

  return {
    ...animationQueue,
    duration,
  };
};
